import { ContactDetails, ExternalLinks } from "../../components/listing";
import { Estimation, Listing } from "../../../api/interfaces/listing";
import { RentomaticFooter } from "./components";
import { PageSubTitle } from "../../components/layout/PageSubtitle";
import { DossierWidget, ProformaWidget } from "../../components/widgets";

interface Props {
  listing?: Listing;
  paidUser: boolean;
  estimation: Estimation | null;
}


export const RentomaticSidebar = (props: Props) => {
  return (
    <div>
      <PageSubTitle>Listing Contact Info</PageSubTitle>
      <div className='mb-5'>
        <div className="mb-3"><ContactDetails listing={props.listing} /></div>
        <ExternalLinks enableMls listing={props.listing} />
      </div>
      {props.listing && props.listing.property_type !== "Residential Lease" && (
        <>
          <PageSubTitle>Next steps</PageSubTitle>
          <ProformaWidget
            className='mb-3 withBoxShadow'
            enabled={props.paidUser}
            estimatedReturn={props.estimation?.return_prediction}
            listingUrl={`/proforma/${props.listing.listing_key}`}
          />
          <DossierWidget
            className='mb-3 withBoxShadow'
            enabled={props.paidUser}
            estimatedReturn={props.estimation?.return_prediction}
            listingKey={props.listing.listing_key}
          />
        </>
      )}
      <RentomaticFooter />
    </div>
  );
};
