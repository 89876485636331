import React, { useState, useEffect } from "react";

import { Navigate, useSearchParams, useNavigate } from "react-router-dom";
import { useApi } from "../../../api/useApi";
import { Button } from "react-bootstrap";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { PageSubTitle } from "../../components/layout/PageSubtitle";

export const FinishSubscription = () => {
  const apiContext = useApi();
  const [status, setStatus] = useState(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("session_id");

  useEffect(() => {
    
    apiContext?.getSubscriptionStatus(sessionId).then((res) => {
      setStatus(res.status);
    });
  }, [apiContext]);

  return (
    <>
      {status === "open" && <Navigate to='/dashboard/subscribe' />}
      {status === "complete" && (
        <>
          <PageSubTitle>We're fetching your subscription status</PageSubTitle>
          <LoadingIndicator />
          &nbsp; Loading...
        </>
      )}
      {status !== "complete" && <>
      <PageSubTitle>Success!</PageSubTitle>
      <Button onClick={() => navigate('/dashboard')}>Return to your plan page</Button>
      </>}
    </>
  );
};
