import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { User, LogOut, ArrowLeftCircle, ArrowRightCircle } from "react-feather";
import { useApi } from "../../../api/useApi";
import styled from "styled-components";
import { SubjectListing } from "../SubjectListing";

const NavigationWrapper = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  z-index: 1000;
  position: absolute;
  border-bottom: 1px solid #000;
`;

const NavigationButtons = styled.div`
  position: absolute;
  height: 40px;
  line-height: 40px;
  left: 90px;

  button {
    background: none;
    border: none;
    padding: 0;
  }
`;

export const Navigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [historyStack, setHistoryStack] = useState<string[]>([]);

  const context = useApi();
  const [user, setUser] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await context?.getUserData();
        setUser(
          `${data?.first_name} ${data?.last_name ? data?.last_name[0] : ""}`
        );
      } catch (error) {
        console.log(error);
      }
    };

    fetchData().catch(console.error);
  }, []);

  if (user === null) {
    return null;
  }
  const canGoBack =
    location.key !== "default" && location.pathname !== "/not_found";

  const canGoForward = historyStack.length > 0;

  const goBack = () => {
    navigate(-1);
    setHistoryStack([...historyStack, location.key]);
  };

  const goForward = () => {
    navigate(1);
    historyStack.pop();
    setHistoryStack([...historyStack]);
  };

  return (
    <NavigationWrapper>
      <NavigationButtons>
        <button disabled={!canGoBack} onClick={() => goBack()}>
          <ArrowLeftCircle />
        </button>
        <button disabled={!canGoForward} onClick={() => goForward()}>
          <ArrowRightCircle />
        </button>
      </NavigationButtons>
      <SubjectListing />

      <div className='accountDropdown'>
        <Dropdown>
          <Dropdown.Toggle>
            {user} <User />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item href='/#/accounts/profile'>
              Account <User />
            </Dropdown.Item>
            <Dropdown.Item href='/#/accounts/logout'>
              Logout <LogOut />
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </NavigationWrapper>
  );
};
