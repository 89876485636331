import { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { User } from "../../../api/interfaces/user";
import { ContentWithSideBar } from "../../components/layout";

const TermsContainer = () => {
  const location = useLocation();
  const terms: string = location.state.terms;

  return (
    <div className='mt-3'>

      <div
        className='repAgreement'
        dangerouslySetInnerHTML={{ __html: terms }}
      ></div>
    </div>
  );
};

export const TermsSidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user: User = location.state.user;
  const [fullName, setFullName] = useState<string>("");
  const userFullName = `${user.first_name} ${user.last_name}`;

  const goToRegistration = (signed: boolean) => {
    navigate("/accounts/register", {
      state: {
        user,
        signed,
      },
    });
  };

  return (
    <Container>
      <Row>
        <Col>
          <br />
          <br />
          <br />
          <br />
          <p>
            By clicking “Create Account” I agree that my typed-in signature will be the electronic representation of my signature for the purposes of entering into this Agreement.
          </p>
          <b>Type your name exactly as it appears:</b>
          <br />
          <br />
          {userFullName}

          <Form
            className="mt-2"
            onSubmit={(e) => {
              e.preventDefault();
              goToRegistration(true);
            }}
          >

            <Form.Control
              className="mb-3"
              onChange={(e) => setFullName(e.target.value)}
              type='text'
              required
            />
            <Button
              variant='primary'
              type='submit'
              disabled={userFullName.toLowerCase() !== fullName.toLowerCase()}
            >
              Create Account
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export const AcceptTerms = () => {
  return (
    <ContentWithSideBar
      center={<TermsContainer />}
      sidebar={<TermsSidebar />}
    />
  );
};
