import { Col, Container, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Module } from "./module.type";
import { useModules } from "./module.hook";
import { LoadingIndicator } from "../../components";

const ModuleBoxRow = styled(Row)`
  padding: 10px;
  border-radius: 5px;
  border: 0.5px solid #8C8C8C;
  background: #FFF;
`;

const ModuleImageCol = styled(Col)`
  flex: 0 0 130px;
  padding: 0px;
`;
const ModuleDescriptionCol = styled(Col)`
  padding-right: 0px;

  color: #333;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;

  b {
    font-size: 16px;
    font-weight: 600;
    line-height: 150%;
  }
`;


const OpenModuleBtn = styled.button`
  line-height: 17px;
  height: 17px;
  box-sizing: border-box !important;
  width: 40px;
  box-sizing: initial;
  border-radius: 5px;
  background: #4286f7;
  border: none;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
`;


const ModuleBox = (props: Module) => {
  const navigate = useNavigate();
  const handleModuleOpen = async (selectedModule: Module) => {
    if (!selectedModule.added) {
      navigate("/dashboard");
      return;
    }
    navigate(selectedModule.url);
  };
  return (
    <Container key={props.moduleId} className="mb-3">
      <ModuleBoxRow className="withBoxShadow">
      <ModuleImageCol>
        <Image width={125} rounded src={props.imagePath} />
      </ModuleImageCol>
      <ModuleDescriptionCol>
        <div className="fright">
          {props.added && (
            <OpenModuleBtn onClick={() => handleModuleOpen(props)}>
              OPEN
            </OpenModuleBtn>
          )}
          {!props.added && props.price}
        </div>
        <b>{props.title}</b>
        <p className="mt-2">{props.description}</p>
      </ModuleDescriptionCol>
    </ModuleBoxRow>
    </Container>
  );
};

export function Dashboard() {
  const [modules, loading] = useModules();
  if (loading) {
    return <LoadingIndicator />;
  }
  return (
    <>
      {modules.map((moduleProps, index) => (
        <ModuleBox key={moduleProps.moduleId} {...moduleProps} />
      ))}
    </>
  );
}
