import { Col, Image, Row } from "react-bootstrap";
import styled from "styled-components";
import { CTABtn } from "./CTABtn";
import { formatPercentDecimal } from "../../utils";

const Wrapper = styled.div`
  background: #fff;
  border-radius: 8px;
  padding: 12px;
  color: #000;
`;

const ImageCol = styled(Col)`
  flex: 0 0 85px;
`;

const BtnCol = styled(Col)`
  align-content: space-evenly;
`;

const EstReturn = styled.div`
  font-weight: bold;
  font-size: 26px;
  line-height: 26px;
  color: #000;

  & div:nth-child(2n) {
    font-size: 12px;
    font-weight: 100;
    line-height: 12px;
    margin-left: 5px;
  }
`;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  enabled: boolean;
  listingUrl: string;
  estimatedReturn?: number;
}

export const ProformaWidget = (props: Props) => {
  return (
    <Wrapper className={props.className}>
      <Row>
        <ImageCol>
          <Image
            rounded
            src='/images/module_icons/proformanator-logo.png'
            width={85}
          />
        </ImageCol>
        <BtnCol>
          <EstReturn className="mb-2">
            <div className="fleft">
              {props.estimatedReturn &&
                formatPercentDecimal(props.estimatedReturn)}
            </div>
            &nbsp;
            <div className="fleft">
              Est
              <br />
              Return
            </div>
            <div className="clear"></div>
          </EstReturn>
          <CTABtn enabled={props.enabled} url={props.listingUrl} title="OPEN PRO FORMA" />
        </BtnCol>
      </Row>
    </Wrapper>
  );
};
